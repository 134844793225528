<script setup lang="ts">
import type { serviceBenefits } from '@locales/ko/serviceBenefits'

const config = useRuntimeConfig()
const { t, locale, messages } = useI18n()
const benefits = computed(() =>
  (
    messages.value[locale.value].serviceBenefits as typeof serviceBenefits
  ).benefits.map((_, index) => t(`serviceBenefits.benefits[${index}]`))
)

const handleOnGoMoreBenefits = () => {
  useNavigations({ external: true, url: config.public.NOTION_URL })
}
</script>

<template>
  <ClientOnly>
    <NovaBoxBase class="nova-service-benefits">
      <div class="contents">
        <div class="header">
          <div :class="['inner', `type-${locale === 'ko' ? 1 : 2}`]">
            <img
              class="bg-img bubble"
              src="@assets/images/image-bubble.svg?url"
              alt=""
            />
            <img
              class="bg-img touch"
              src="@assets/images/image-touch.svg?url"
              alt=""
            />
            <h4 v-dompurify-html="t('serviceBenefits.title')" class="title" />
          </div>
        </div>
        <div class="body">
          <dl
            v-for="(benefit, index) in benefits"
            :key="index"
            class="description"
          >
            <dt>
              <img
                class="icon"
                src="../../assets/images/image-mention.svg?url"
                alt=""
              />
            </dt>
            <dd v-dompurify-html="benefit" />
          </dl>
        </div>
        <div class="footer">
          <NovaButtonText
            :label="t('serviceBenefits.goMoreBenefits')"
            :theme="'primary-blue-light'"
            @click="handleOnGoMoreBenefits"
          />
        </div>
      </div>
    </NovaBoxBase>
  </ClientOnly>
</template>

<style lang="scss" scoped>
.nova-service-benefits {
  padding: 6px;
  border-radius: 10px;

  > .contents {
    padding: 30px 13px 16px;
    border: 7px solid $color-border-1;
    border-radius: 7px;
    overflow: hidden;

    .header {
      > .inner {
        position: relative;
        display: inline-block;
        max-width: calc(100% - 34px);

        &.type-1 {
          img.bg-img {
            &.bubble {
              top: -12px;
              left: 100%;
            }

            &.touch {
              top: calc(100% - 32px);
              left: calc(100% - 4px);
            }
          }
        }

        &.type-2 {
          img.bg-img {
            &.bubble {
              top: -12px;
              left: 100%;
            }

            &.touch {
              top: calc(100% - 36px);
              left: calc(100% - 36px);
              transform: scale(0.8);
            }
          }
        }

        img.bg-img {
          position: absolute;
          z-index: 2;
        }

        h4.title {
          @include text-style($text-custom-24-bold);
          color: $color-text-4;
          z-index: 3;
        }
      }
    }

    .body {
      display: flex;
      flex-direction: column;
      gap: 30px;
      margin-top: 35px;

      dl.description {
        display: flex;
        align-items: center;
        gap: 8px;

        dt {
          flex-shrink: 0;
          font-size: 0;

          .icon {
            width: 24px;
            height: 24px;
          }
        }

        dd {
          font-size: 12px;
          line-height: 14px;
          font-weight: 700;
          letter-spacing: -5%;
          color: #939bb6;
        }
      }
    }

    .footer {
      display: flex;
      justify-content: center;
      margin-top: 15px;
    }
  }
}

.box-rewards {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  > .contents {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .header {
      display: flex;
      flex-direction: column;
      gap: 6px;

      > .title {
        @include text-style($text-display-bold);
        color: $color-text-2;
      }

      > .description {
        @include text-style($text-body-14-regular);
        color: $color-text-3;
      }
    }

    .rewords-list-container {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .rewords {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .reword-copy {
          display: flex;
          gap: 10px;

          .icon-box {
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            border-radius: 4px;

            &.theme {
              &-yellow {
                background-color: $color-secondary-yellow-light-10;
                color: $color-secondary-yellow-light-80;
              }

              &-red {
                background-color: $color-secondary-red-light-10;
                color: $color-secondary-red-light-80;
              }

              &-blue {
                background-color: $color-secondary-blue-light-10;
                color: $color-secondary-blue-light-80;
              }

              &-green {
                background-color: $color-secondary-green-light-10;
                color: $color-secondary-green-light-80;
              }

              &-purple {
                background-color: $color-secondary-purple-light-10;
                color: $color-secondary-purple-light-80;
              }
            }
          }

          .info-wrap {
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 12px;

            > .label {
              flex-grow: 1;
              @include text-style($text-body-12-bold);
              color: $color-text-4;
            }

            > .count {
              flex-shrink: 0;
              @include text-style($text-body-10-regular);
              color: $color-text-4;
            }
          }
        }
      }
    }
  }
}
</style>
