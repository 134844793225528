<script setup lang="ts">
const userStore = useUserStore()
const { t } = useI18n()
const { gtEvent } = useGoogleTag()

const handleOnClick = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '오른쪽 사이드 메뉴 버튼',
    eventLabel: t('dailyCheck.dailyCheckin'),
    eventSlot: '',
    eventI18nAddr: t('dailyCheck.dailyCheckin'),
    eventComponent: 'Button',
  })
  if (!userStore.user) return
  useNavigations({ external: false, url: '/daily-check' })
}
</script>

<template>
  <ClientOnly>
    <NovaButtonWhiteBox :on-click="handleOnClick">
      <template #default>
        <NovaIcon
          :icon="{ type: 'outline', name: 'check-circle' }"
          :size="27"
        />
        <p>{{ $t('dailyCheck.dailyCheckin') }}</p>
      </template>
    </NovaButtonWhiteBox>
  </ClientOnly>
</template>
