<script setup lang="ts">
import { ROUTES } from '@configs'

const userStore = useUserStore()
const isFeed = computed(() => {
  const routeName = useGetRouteName()
  return routeName.includes(ROUTES.FEED.name)
})
</script>

<template>
  <NovaLayoutWrapper>
    <NovaHeader />

    <NovaLayoutMain :page-mode="true">
      <template #left>
        <NovaHeaderLeftSide :scroll="true">
          <NovaBoxCategories />
          <!--<NovaBoxRewards />-->
          <!--<NovaBoxSuggestSignUp />-->
        </NovaHeaderLeftSide>
      </template>

      <template #center>
        <slot />
      </template>

      <template #right>
        <ClientOnly>
          <NovaHeaderRightSide :scroll="true">
            <NovaBoxUser v-if="userStore.isSignIn" />
            <NovaBoxMyRewards v-if="userStore.isSignIn" />
            <NovaBoxHelpCenter v-if="userStore.isSignIn" />
            <NovaBoxAttendanceCheck v-if="userStore.isSignIn" />
            <NovaBoxRewardsActivity v-if="!userStore.isSignIn" />
            <!--          <NovaBoxHashtagRankings />-->
            <NovaBoxLiveList v-if="isFeed" />
            <NovaBoxCorporationInfo />
          </NovaHeaderRightSide>
        </ClientOnly>
      </template>
    </NovaLayoutMain>
  </NovaLayoutWrapper>
</template>
