<script setup lang="ts">
const { t } = useI18n()
const { gtEvent } = useGoogleTag()
const extend = ref(false)
const copyright = computed(() =>
  t('corporationInfo.copyright', { year: new Date().getFullYear() })
)
const corpInfos = computed(() => [
  {
    label: t('corporationInfo.properties.name'),
    value: t('corporationInfo.infos.name'),
  },
  {
    label: t('corporationInfo.properties.owner'),
    value: t('corporationInfo.infos.owner'),
  },
  {
    label: t('corporationInfo.properties.address'),
    value: t('corporationInfo.infos.address'),
  },
  {
    label: t('corporationInfo.properties.businessNumber'),
    value: t('corporationInfo.infos.businessNumber'),
  },
  {
    label: t('corporationInfo.properties.tel'),
    value: `<a href="tel:${t('corporationInfo.infos.tel')}">${t(
      'corporationInfo.infos.tel'
    )}</a>`,
  },
  {
    label: t('corporationInfo.properties.fax'),
    value: t('corporationInfo.infos.fax'),
  },
  {
    label: t('corporationInfo.properties.email'),
    value: `<a href="mailto:${t('corporationInfo.infos.email')}">${t(
      'corporationInfo.infos.email'
    )}</a>`,
  },
])

const handleOnToggleExtend = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '오른쪽 사이드 메뉴 Info',
    eventLabel: t('corporationInfo.title'),
    eventSlot: '',
    eventI18nAddr: '',
    eventComponent: 'Button',
  })
  extend.value = !extend.value
}
</script>

<template>
  <NovaDivider class="divider" />

  <div :class="['corporation-info', { extend }]">
    <button type="button" class="extender" @click="handleOnToggleExtend">
      <NovaIcon
        :icon="{ type: 'outline', name: 'carrot-bottom' }"
        :size="16"
        class="icon-extender"
      />
      <span class="label">{{ $t('corporationInfo.title') }}</span>
    </button>

    <div class="infos">
      <dl v-for="info in corpInfos" :key="info.label" class="info-item">
        <dt>{{ info.label }}</dt>
        <dd v-html="info.value" />
      </dl>
    </div>

    <div class="copy-right">{{ copyright }}</div>
  </div>
</template>

<style lang="scss" scoped>
.divider {
  flex-shrink: 0;
}

$padding-left: 16px + 6px;

.corporation-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  color: $color-text-3;

  &.extend {
    .extender > .icon-extender {
      color: $color-primary-blue;
      transform: rotate(180deg);
    }

    .infos {
      display: block;
    }
  }

  .extender {
    display: flex;
    align-items: center;
    gap: 6px;
    text-align: left;

    > .icon-extender {
      @include transition(color 0.14s ease-in-out, transform 0.14s ease-in-out);
    }

    > .label {
      @include text-style($text-body-12-bold);
    }
  }

  .infos {
    display: none;
    padding-left: $padding-left;

    .info-item {
      display: inline;
      @include text-style($text-body-12-regular);

      &:first-of-type:before {
        display: none;
      }

      dt,
      dd {
        word-break: break-all;
        display: inline;
      }

      dt {
        margin-right: 2px;

        &:after {
          content: ':';
        }
      }

      &:before {
        content: '/';
        margin: 0 4px;
      }
    }
  }

  .infos[style*='display: block'] + .copy-right {
    margin-top: 8px;
  }

  .copy-right {
    padding-left: $padding-left;
    @include text-style($text-body-12-regular);
  }
}
</style>
