<script setup lang="ts">
import type { NovaBoxCategoriesChildItemProps } from './NovaBoxCategoriesChildItem.types'

const props = defineProps<NovaBoxCategoriesChildItemProps>()

const route = useRoute()
const isOn = computed(() =>
  route.query.cmtyNttCtgrySn
    ? Number(route.query.cmtyNttCtgrySn) === props.category.cmtyNttCtgrySn
    : props.category.ctgryDfltCeckAt === 'Y'
)
</script>

<template>
  <div :class="['category-item', { on: isOn }]">
    <div class="label-wrap">
      <span class="label">{{ category.nttCtgryNm }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.category-item {
  display: flex;
  padding: 6px 5px;
  text-align: left;
  border-radius: 6px;
  @include transition(background-color 0.2s);
  cursor: pointer;

  &.on {
    background-color: $color-bg-1;
  }

  @media (hover: hover) {
    &:hover {
      background-color: $color-bg-custom-2;
    }
  }

  &:active {
    background-color: $color-bg-custom-3;
  }

  .label-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    > .label {
      @include text-style($text-body-13-regular);
    }
  }
}
</style>
