<script setup lang="ts">
const nuxtApp = useNuxtApp()
const postStore = usePostStore()
const isLoading = ref(true)

nuxtApp.hook('page:finish', () => {
  isLoading.value = false
})
</script>

<template>
  <NovaBoxBase class="box-categories">
    <div class="contents">
      <h4 class="title">{{ $t('category') }}</h4>
    </div>

    <div v-if="isLoading" class="categories">
      <NovaLoadingIndicator :size="80" :fill="true" :bg-bright="'light'" />
    </div>

    <div v-if="!isLoading && !postStore.categories.length" class="categories">
      <NovaBoxEmptyMessage :message="$t('statusMessages.categories.empty')" />
    </div>

    <div v-if="!isLoading && postStore.categories.length" class="categories">
      <NovaBoxCategoriesItem
        v-for="category in postStore.categories"
        :key="category.cmtyNttCtgrySn"
        :category="category"
      />
    </div>
  </NovaBoxBase>
</template>

<style lang="scss" scoped>
.box-categories {
  flex-direction: column;
  gap: 16px !important;
  width: 100%;
  padding: 0 !important;
  //overflow: hidden;

  > .contents {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 20px 20px 0;

    > .title {
      @include text-style($text-display-bold);
      color: $color-text-2;
    }
  }

  > .categories {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 20px 20px;
    overflow-y: overlay;
  }
}
</style>
